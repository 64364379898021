.visible-modal {
  display: block;
  background-color: rgba(22, 22, 22, 0.3);
  transition: top 1s, opacity 1s !important;
}

.visible-modal-content-4 {
  background-size: cover;
  box-shadow: rgb(1, 26, 36) 1px 5px 24px 0px;
}
