.visible-modal {
  display: block;
  background-color: rgba(22, 22, 22, 0.3);
  transition: top 1s, opacity 1s !important;
}

.visible-modal-content-3 {
  /* background-image: url("../../../assets/images/dashboard/login-bg.png"); */
  background-size: cover;
  box-shadow: rgb(34, 3, 59) 1px 5px 24px 0px;
}
