.product-detail-div {
  padding: 3rem !important;
  position: fixed;
  z-index: 5;
}

.order-details-flexbox {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(223, 220, 220);
}
.order-details-flexbox div {
  max-width: 50%;
}

.popover-body-container {
  max-height: 50vh;
  overflow-y: scroll;
}

.btn {
  font-size: 10px !important;
  padding: 0.5rem 1rem;
}

.center-head {
  color: #202120;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: lighter;
  margin: 10px 0 30px;
  text-align: center;
  position: relative;
}
.center-head:after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  border-bottom: 1px solid #ccc;
  top: 50%;
  z-index: 9;
}

.center-head span {
  display: inline-block;
  padding: 0 20px;
  position: relative;
  z-index: 99;
  font-weight: 600;
}

@media (min-width: 1000px) {
  .center-head {
    margin-top: 40px;
  }
}
