.visible-modal {
  display: block;
  background-color: rgba(22, 22, 22, 0.3);
  transition: top 1s, opacity 1s !important;
}

.visible-modal-content-2 {
  background-size: cover;
}

.for-min-height {
  min-height: 600px;
  max-height: 600px;
  overflow-y: scroll;
}

.ck-editor__editable_inline {
  min-height: 400px !important;
}
.ck-editor__editable_inline p {
  color: #222222;
}

@media (min-width: 1000px) {
  .visible-modal-content-2 {
    min-width: 1000px;
  }

  .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
}
