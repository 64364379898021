.sticker-container {
  height: 380px;
  width: 570px;
  background-color: white;

  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-left: -120px;
}

.flex-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.barcode {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.carton-no {
  font-weight: bold;
  font-size: 100px;
  padding: 70px;
  border: 1px solid black;
  margin-top: 40px;
}

.lot-no {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  font-size: 20px;
  margin-top: 20px;
}

.barcode-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .sticker-container {
    height: 380px;
    width: 570px;
    background-color: white;
    margin-top: 100px;
    margin-left: -120px;

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
